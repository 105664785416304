import React from 'react';
import './dank.css';
import dankeImg from '../images/danke.png';
import BasicStructure from '../components/basic-structure/basic-structure';
import TitleMetaTags from '../components/title-meta-tags/title-meta-tags';
import Header from '../components/header/header';
import Menubar from '../components/menu/menubar';
import BurgerMenu from '../components/burgermenu/burgermenu';
import Sidebar from '../components/sidebar/sidebar';
import ScrollUp from '../components/scroll-up/scroll-up';
import ContentContainer from '../components/content-container/content-container';
import Footer from '../components/footer/footer';

function Dank(): JSX.Element {
  return (
    <BasicStructure>
      <TitleMetaTags title="Danke" />
      <Header>
        <Menubar styleClass="main-menu" />
        <BurgerMenu />
      </Header>
      <Sidebar>
        <ScrollUp />
      </Sidebar>
      <ContentContainer>
        <section className="dank__container">
          <img className="dank__title-image" src={dankeImg} />
          <div className="dank__danksagung">
            Wir danken allen, die unsere Projekte ideell und materiell
            unterstützen.
            <br />
            Mit unserer täglichen Arbeit, an der auch der Stifter, Herr Erich
            Fischer nach wie vor prägend teilnimmt, versuchen wir, mit Ihrer
            unentbehrlichen Unterstützung, unsere gemeinnützigen Ziele zu
            verwirklichen. Und das gelingt uns eigentlich auch ganz gut.
          </div>

          <div className="dank__foerderer">
            <ul className="dank__auflistung">
              <li className="dank__sponsoren dank__strip-styling">
                Stiftung ANTENNE BAYERN hilft, Ismaning
              </li>
              <li className="dank__sponsoren">
                Prof. Hermann Auer Stiftung, München
              </li>
              <li className="dank__sponsoren dank__strip-styling">
                Bayerische Landeskoordinationsstelle Musik
              </li>
              <li className="dank__sponsoren">
                Bayerisches Staatsministerium für Unterricht und Kultus
                (Kulturfonds Bayern)
              </li>
              <li className="dank__sponsoren dank__strip-styling">
                Bayerisches Staatsministerium für Wissenschaft und Kunst
                (Kulturfonds Bayern)
              </li>
              <li className="dank__sponsoren">
                Prof. Otto Beisheim Stiftung, München
              </li>
              <li className="dank__sponsoren dank__strip-styling">
                Bezirk Oberbayern
              </li>
              <li className="dank__sponsoren ">Brost-Stiftung, Essen</li>
              <li className="dank__sponsoren dank__strip-styling">
                Castringius Kinder- und Jugendstiftung
              </li>
              <li className="dank__sponsoren">Commerzbank AG Bayern</li>
              <li className="dank__sponsoren dank__strip-styling">
                Edith-Haberland-Wagner-Stiftung
              </li>
              <li className="dank__sponsoren">
                Josef und Luise Kraft Stiftung
              </li>
              <li className="dank__sponsoren dank__strip-styling">
                LandKULTUR
              </li>
              <li className="dank__sponsoren ">Prause-Schindelmann-Stiftung</li>
              <li className="dank__sponsoren dank__strip-styling">
                Ursula und Walter Schatt Stiftung, Rohrdorf
              </li>
              <li className="dank__sponsoren ">Sternstunden e.V.</li>
              <li className="dank__sponsoren dank__strip-styling">
                SZ Adventskalender
              </li>
              <li className="dank__sponsoren ">
                Marion von Tessin-Stiftung, München
              </li>
              <li className="dank__sponsoren dank__strip-styling">
                UBS Optimus Foundation Deutschland, Frankfurt am Main
              </li>
              <li className="dank__sponsoren ">
                Vereinigung der Bayerischen Wirtschaft e.V.
              </li>
              <li className="dank__sponsoren dank__strip-styling">
                Waisenhausstiftung Stadtamhof
              </li>
              <li className="dank__sponsoren ">
                Antonie Zauner Stiftung, München
              </li>
              <li className="dank__sponsoren dank__strip-styling">
                Stiftung Zukunft Mensch
              </li>
            </ul>
          </div>
        </section>
      </ContentContainer>
      <Footer />
    </BasicStructure>
  );
}

export default Dank;
